<template>
  <div class="management-area">
    <x-table
      :no-data-text="
        CA('managementArea_check') ? '暂无数据' : '暂无数据查询权限'
      "
      :columns="table.columns"
      :data="table.data"
      :loading="table.loading"
      :config="config"
      @add="add"
      @search="search"
      @page-change="pageChange"
      @page-size-change="pageSizeChange"
      :showSummary="true"
      :summaryMethod="handleSummary"
    ></x-table>

    <Modal
      v-model="modal.show"
      :title="modal.title"
      @on-visible-change="modalShow"
      :width="800"
    >
      <Form ref="form" :model="form" :rules="rules" :label-width="110">
        <Row>
          <Col span="11">
            <FormItem label="管理区域名称" prop="name">
              <Input clearable v-model="form.name"></Input>
            </FormItem>
          </Col>
          <Col span="11" offset="1">
            <FormItem label="管理区域面积" prop="area">
              <Input v-model="form.area">
                <span slot="append">㎡</span>
              </Input>
            </FormItem>
          </Col>
        </Row>
        <Row>
          <Col span="11">
            <FormItem label="负责人" prop="chargePerson">
              <Input clearable v-model="form.chargePerson"></Input>
            </FormItem>
          </Col>
          <Col span="11" offset="1">
            <FormItem label="负责人电话" prop="chargePersonTel">
              <Input clearable v-model="form.chargePersonTel"></Input>
            </FormItem>
          </Col>
        </Row>
        <Row>
          <Col span="11">
            <FormItem label="详细地址" prop="address">
              <Input clearable v-model="form.address"></Input>
            </FormItem>
          </Col>
          <Col span="11" offset="1">
            <FormItem label="区域" prop="region">
              <!-- <Input
            v-model="form.regionName"
            readonly
            @on-focus="
              () => {
                chooseArea.show = true;
              }
            "
          ></Input> -->
              <Cascader
                :data="areaList"
                v-model="form.region"
                change-on-select
                :load-data="loadData"
                placeholder="请选择区域"
              ></Cascader> </FormItem
          ></Col>
        </Row>
        <Row>
          <Col span="11">
            <FormItem label="管理区域绘制" prop="mapPosition">
              <Input v-model="form.mapPosition" disabled>
                <span
                  slot="append"
                  class="iconfont"
                  style="cursor: pointer"
                  @click="drawMap"
                  >&#xe648;</span
                >
              </Input>
            </FormItem>
          </Col>
          <Col span="11" offset="1">
            <FormItem label="是否公有">
              <Select v-model="form.attributes">
                <Option value="1">是</Option>
                <Option value="2">否</Option>
              </Select>
            </FormItem>
          </Col>
          <Col span="11">
            <FormItem label="种植品种" prop='species'>
              <Select v-model="form.species">
                <Option
                  v-for="item in category"
                  :value="item.id"
                  :key="item.id"
                  >{{ item.name }}</Option
                >
              </Select>
            </FormItem>
          </Col>
        </Row>
        <FormItem label="备注">
          <Input
            type="textarea"
            :rows="3"
            clearable
            v-model="form.remark"
          ></Input>
        </FormItem>
        <FormItem label="上传图片">
          <upload-image
            v-model="form.imgUrl"
            :max="1"
            :clear-flag="!modal.show"
          ></upload-image>
        </FormItem>
      </Form>
      <p slot="footer" style="align-right: center">
        <Button type="primary" :loading="modal.submitLoading" @click="submit"
          >提交</Button
        >
        <Button @click="() => (modal.show = false)">取消</Button>
      </p>
    </Modal>

    <draw-map-modal
      v-model="drawMapModalShow"
      @draw-finish="drawFinish"
      :mapPosition="form.mapPosition"
    ></draw-map-modal>
    <!-- <area-modal
      v-model="chooseArea.show"
      @on-change="getArea"
      :areaIds="areaIds"
    ></area-modal> -->
    <somaAreaModal
      v-model="somaAreaModalData.show"
      :farm="somaAreaModalData.farm"
    ></somaAreaModal>
    <Modal
      v-model="editorModal"
      title="基地信息配置"
      :width="900"
      @on-visible-change="editorModalShow"
    >
      <editor
        ref="detail"
        @editorContent="getEditorContent"
        v-model="remark"
        v-if="editorShow"
      ></editor>
      <p slot="footer" style="text-align: center">
        <Button type="primary" :loading="editorLoading" @click="infoSubmit"
          >提交</Button
        >
        <Button @click="() => (editorModal = false)">取消</Button>
      </p>
    </Modal>
  </div>
</template>

<script>
// import areaModal from "@/components/areaModal/newIndex";
import drawMapModal from "./drawMapModal";
import CU from "@/common/util";
import somaAreaModal from "../someArea";
import editor from "@/components/editor";

export default {
  name: "",
  components: {
    drawMapModal,
    // areaModal,
    somaAreaModal,
    editor,
  },
  data() {
    return {
      table: {
        columns: [
          {
            title: "管理区域图片",
            width: 120,
            key: "",
            align: "center",
            render: (h, { row }) => {
              if (row.imgUrl == "imageundefined" || row.imgUrl == "")
                return <span>--</span>;
              return (
                <img
                  src={row.servicePath + row.imgUrl}
                  style="width: 35px;height: 35px;"
                />
              );
            },
          },
          {
            title: "管理区域名称",
            key: "name",
            minWidth: 160,
          },
          {
            title: "种植品种",
            key: "speciesName",
          },
          {
            title: "管理区域面积",
            width: 120,
            key: "area",
            render: (h, { row }) => {
              return <span>{row.area + "㎡"}</span>;
            },
          },
          {
            title: "负责人",
            width: 150,
            key: "chargePerson",
          },
          {
            title: "负责人电话",
            width: 150,
            key: "chargePersonTel",
          },
          {
            title: "管理区域地址",
            width: 260,
            key: "address",
          },
          {
            title: "创建时间",
            width: 180,
            key: "createTime",
          },
          {
            title: "操作",
            width: 300,
            render: (h, { row }) => {
              return (
                <div>
                  <a
                    style="margin-right:10px"
                    onClick={() => this.infoConfig(row)}
                  >
                    基地信息
                  </a>
                  {this.CA("managementArea_edit") && (
                    <a
                      style="margin-right: 10px"
                      onClick={() => this.edit(row)}
                    >
                      编辑
                    </a>
                  )}
                  {this.CA("someArea_check") && (
                    <a
                      style="margin-right: 10px"
                      onClick={() => this.block(row)}
                    >
                      片区
                    </a>
                  )}
                  {this.CA("managementArea_del") && (
                    <Poptip
                      confirm
                      transfer
                      title="确定删除吗?"
                      on-on-ok={() => this.del(row.id)}
                    >
                      <a>删除</a>
                    </Poptip>
                  )}
                </div>
              );
            },
          },
        ],
        data: [],
        loading: false,
      },
      page: {
        pageNo: 1,
        pageSize: 10,
        total: 0,
      },

      modal: {
        show: false,
        title: "",
        submitLoading: false,
      },

      form: {
        id: "",
        name: "",
        area: "",
        chargePerson: "",
        chargePersonTel: "",
        imgUrl: "",
        remark: "",
        address: "",
        siteType: "4",
        mapPosition: "",
        thirdLongLat: "",
        //区域id
        regionCode: "",
        //区域名称
        regionName: "",
        region: [],
        attributes: "",
      },
      //已选择的区域id和名称列表
      regionCode: [],
      regionName: [],
      rules: {
        name: [{ required: true, message: "请填写管理区域名称" }],
        area: [{ required: true, message: "请填写管理区域面积" }],
        chargePerson: [{ required: true, message: "请填写负责人" }],
        chargePersonTel: [
          { required: true, message: "请填写联系电话" },
          {
            validator(rule, value, callback) {
              if (CU.validatePhone(value)) {
                callback();
                return;
              }
              if (CU.validateLandline(value)) {
                callback();
                return;
              }
              callback(rule.message);
            },
            message: "手机号格式不正确",
            trigger: "change",
          },
        ],
        address: [{ required: true, message: "请填写管理区域地址" }],
        mapPosition: [{ required: true, message: "请绘制管理区域地图" }],
        region: [{ required: true, message: "请选择区域" }],
        species: [{ required: true, message: "请选择种植品种" }],
      },
      drawMapModalShow: false,
      search_data: {},

      //区域选择弹窗
      chooseArea: {
        show: false,
      },
      //区域id
      areaIds: "",

      somaAreaModalData: {
        show: false,
        farm: null,
      },
      //省市区列表
      areaList: [],
      editorModal: false,
      editorShow: false,
      remark: "",
      farmId: "",
      editorLoading: false,
      category:[]
    };
  },
  computed: {
    config() {
      let config = {
        filter: {
          add: {
            addBtnName: "新增管理区域",
            ca: "managementArea_add",
          },
          width: 220,
          filterBox: [
            {
              conditionName: "管理区域名称",
              component: "input",
              field: "name",
              defaultValue: "",
            },
            {
              conditionName: "联系人",
              component: "input",
              field: "chargePerson",
              defaultValue: "",
            },
            {
              conditionName: "联系电话",
              component: "input",
              field: "chargePersonTel",
              defaultValue: "",
            },
            {
              conditionName: "地址",
              component: "input",
              field: "address",
              defaultValue: "",
            },
          ],
        },
        page: { ...this.page },
      };
      return config;
    },
  },
  methods: {
    // 打开片区弹窗
    block(row) {
      this.somaAreaModalData.farm = { ...row };
      this.somaAreaModalData.show = true;
    },

    pageChange(pageNo) {
      this.page.pageNo = pageNo;
      this.getList();
    },
    pageSizeChange(pageSize) {
      this.page.pageSize = pageSize;
      this.getList();
    },

    search(data) {
      this.search_data = data;
      this.page.pageNo = 1;
      this.getList();
    },

    add() {
      this.areaIds = "";
      this.modal = {
        show: true,
        title: "新增管理区域",
        submitLoading: false,
      };
    },
    drawMap() {
      this.drawMapModalShow = true;
    },
    drawFinish(data) {
      this.form.mapPosition = JSON.stringify(data);
      let center = data.length > 0 ? JSON.parse(data[0].options.center) : {};
      this.form.thirdLongLat = center.lng + "," + center.lat;
      this.drawMapModalShow = false;
    },

    getList() {
      if (!this.CA("managementArea_check")) return;
      this.table.loading = true;
      this.$post(this.$api.BASE_MANAGE.LIST, {
        pageNo: this.page.pageNo,
        pageSize: this.page.pageSize,
        siteType: "4",
        ...this.search_data,
      })
        .then((res) => {
          this.table.data = res.list;
          this.page.total = +res.total;
        })
        .finally(() => {
          this.table.loading = false;
        });
    },
    //省市区列表接口
    getAreaList() {
      this.$post(this.$api.AREA_MANAGE.LIST, {
        parentId: "100000",
      }).then((res) => {
        res.forEach((item) => {
          item.label = item.name;
          item.value = item.id;
          item.loading = false;
          item.children = [];
        });
        this.areaList = res;
      });
    },
    loadData(item, callback) {
      item.loading = true;
      this.$post(this.$api.AREA_MANAGE.LIST, {
        parentId: item.id,
      })
        .then((res) => {
          res.forEach((item) => {
            item.label = item.name;
            item.value = item.id;
            if (item.hasChild == "true") {
              item.loading = false;
              item.children = [];
            }
          });
          item.children = res;
          callback();
        })
        .finally(() => {
          item.loading = false;
        });
    },
    edit(row) {
      // this.areaIds = row.regionCode;
      for (let key in this.form) {
        this.form[key] = row[key];
      }
      if (row.imgUrl == "imageundefined" || row.imgUrl == "") {
        this.form.imgUrl = "";
      } else {
        this.form.imgUrl = row.servicePath + row.imgUrl;
      }
      // this.form.regionName = row.regionCodeName;
      this.form.region = row.regionCode.split(",");
      this.modal = {
        show: true,
        title: "编辑管理区域",
        submitLoading: false,
      };
    },
    handleSummary({ columns, data }) {
      const sums = {};
      columns.forEach((column, index) => {
        const key = column.key;
        if (index === 0) {
          sums[key] = {
            key,
            value: "统计",
          };
          return;
        }
        const values = data.map((item) => Number(item[key]));
        if (column.key == "area") {
          if (!values.every((value) => isNaN(value))) {
            const v = values.reduce((prev, curr) => {
              const value = Number(curr);
              if (!isNaN(value)) {
                return prev + curr;
              } else {
                return prev;
              }
            }, 0);
            sums[key] = {
              key,
              value: v,
            };
          } else {
            sums[key] = {
              key,
              value: "",
            };
          }
        } else {
          sums[key] = {
            key,
            value: "",
          };
        }
      });
      return sums;
    },
    infoConfig(row) {
      this.farmId = row.id;
      this.remark = row.remark;
      this.editorModal = true;
      this.editorShow = true;
    },
    getEditorContent(content) {
      this.remark = content;
    },
    editorModalShow(visible) {
      if (visible) return;
      this.$refs.detail.editor.txt.html("");
      this.editorShow = false;
    },

    infoSubmit() {
      this.editorLoading = true;
      this.$post(this.$api.BASE_MANAGE.EDIT, {
        id: this.farmId,
        remark: this.remark,
      })
        .then(() => {
          this.getList();
          this.editorModal = false;
        })
        .finally(() => {
          this.editorLoading = false;
        });
    },
    del(id) {
      this.$post(this.$api.BASE_MANAGE.DELETE, {
        id,
      }).then(() => {
        this.$Message.success("删除成功");
        this.getList();
      });
    },
    //获取区域id
    // getArea(value) {
    //   this.regionCode = [];
    //   this.regionName = [];
    //   this.chooseArea.show = false;
    //   if (value.ids.length > 0) {
    //     value.ids.forEach((item) => {
    //       this.regionCode.push(item);
    //     });
    //     value.names.forEach((item) => {
    //       this.regionName.push(item);
    //     });
    //   }
    //   this.form.regionCode = this.regionCode.toString();
    //   this.form.regionName = this.regionName.toString();
    //   this.areaIds = this.form.regionCode
    // },
    modalShow(visible) {
      if (visible) return;
      this.form = {
        id: "",
        name: "",
        area: "",
        chargePerson: "",
        chargePersonTel: "",
        imgUrl: "",
        remark: "",
        address: "",
        siteType: "4",
        mapPosition: "",
        thirdLongLat: "",
        regionCode: "",
        regionName: "",
        region: [],
        attributes: "",
      };
      this.$refs.form.resetFields();
    },
    submit() {
      this.$refs.form.validate().then((res) => {
        if (!res) return;
        let params = { ...this.form };
        this.category.map(item=>{
          if(params.species == item.id){
            params.speciesName = item.name
          }
        })
        // params.species = Number(params.species)
        this.form.imgUrl &&
          (params.imgUrl = "image" + this.form.imgUrl.split("image")[1]);

        params.regionCode = this.form.region.join(",");
        this.modal.submitLoading = true;
        this.$post(
          params.id ? this.$api.BASE_MANAGE.UPDATE : this.$api.BASE_MANAGE.ADD,
          params
        )
          .then(() => {
            this.$Message.success("操作成功！");
            this.getList();
            this.modal.show = false;
          })
          .finally(() => {
            this.modal.submitLoading = false;
          });
      });
    },
    getCategory() {
      console.log(111)
      this.$post(this.$api.USER_PRODUCT.LIST, {
                type: "1"
            }).then((res) => {
        console.log(res);
        this.category = res.list;
      });
    },
  },
  mounted() {
    this.getList();
    this.getAreaList();
    this.getCategory()
  },
};
</script>

<style lang="less" scoped>
.management-area {
  width: 100%;
  height: 100%;
}
</style>