<template>
  <Modal
    footer-hide
    fullscreen
    :value="value"
    @input="(val) => $emit('input', val)"
    :title="farm ? `${farm.name}区域片区划分` : '片区划分'"
  >
    <div class="some-area">
      <x-table
        :no-data-text="CA('someArea_check') ? '暂无数据' : '暂无数据查询权限'"
        :columns="table.columns"
        :data="table.data"
        :loading="table.loading"
        :config="config"
        @add="add"
        @search="search"
        @page-change="pageChange"
      ></x-table>

      <Modal
        v-model="modal.show"
        :title="modal.title"
        @on-visible-change="modalShow"
      >
        <Form ref="form" :model="form" :rules="rules" :label-width="110">
          <FormItem label="片区名称" prop="name">
            <Input clearable v-model="form.name"></Input>
          </FormItem>
          <!-- <FormItem label="所属管理区域" prop="farm">
            <Select v-model="form.farm">
              <Option
                v-for="(item, index) in farmList"
                :key="index"
                :value="item.id + '/' + item.siteType"
                >{{ item.name }}</Option
              >
            </Select>
          </FormItem> -->
          <FormItem label="片区面积" prop="area">
            <Input v-model="form.area">
              <span slot="append">㎡</span>
            </Input>
          </FormItem>
          <FormItem label="片区绘制" prop="mapPosition">
            <Input
              :placeholder="
                form.farm ? '点击右侧绘制片区地图' : '请先选择管理区域'
              "
              v-model="form.mapPosition"
              disabled
            >
              <span
                slot="append"
                class="iconfont"
                :style="{ cursor: form.farm ? 'pointer' : 'not-allowed' }"
                @click="drawMap"
                >&#xe648;</span
              >
            </Input>
          </FormItem>
          <FormItem label="备注">
            <Input
              type="textarea"
              :rows="3"
              clearable
              v-model="form.remark"
            ></Input>
          </FormItem>
        </Form>
        <p slot="footer" style="text-align: center">
          <Button type="primary" :loading="modal.submitLoading" @click="submit"
            >提交</Button
          >
          <Button @click="() => (modal.show = false)">取消</Button>
        </p>
      </Modal>
      <draw-map-modal
        :backgroundPosition="backgroundPosition"
        v-model="drawMapModalShow"
        @draw-finish="drawFinish"
        :mapPosition="form.mapPosition"
      ></draw-map-modal>
    </div>
  </Modal>
</template>

<script>
import drawMapModal from "./drawMapModal";
import CU from "@/common/util";
export default {
  props: {
    value: Boolean,
    farm: Object,
  },
  components: {
    drawMapModal,
  },
  data() {
    return {
      table: {
        columns: [
          {
            title: "片区名称",
            minWidth: 110,
            key: "name",
          },
          {
            title: "所属管理区域",
            minWidth: 110,
            key: "farmName",
          },
          {
            title: "片区面积",
            minWidth: 110,
            render: (h, { row }) => {
              return <span>{row.area + "㎡"}</span>;
            },
          },
          {
            title: "备注",
            minWidth: 100,
            key: "remark",
          },
          {
            title: "操作",
            width: 200,
            render: (h, { row }) => {
              return (
                <div>
                  {this.CA("someArea_edit") && (
                    <a
                      style="margin-right: 10px"
                      onClick={() => this.edit(row)}
                    >
                      编辑
                    </a>
                  )}
                  {this.CA("someArea_del") && (
                    <Poptip
                      confirm
                      transfer
                      title="确定删除吗?"
                      on-on-ok={() => this.del(row.id)}
                    >
                      <a>删除</a>
                    </Poptip>
                  )}
                </div>
              );
            },
          },
        ],
        data: [],
        loading: false,
      },
      page: {
        pageNo: 1,
        pageSize: 10,
        total: 0,
      },
      modal: {
        show: false,
        title: "",
        submitLoading: false,
      },
      form: {
        id: "",
        name: "",
        area: "",
        remark: "",
        mapPosition: "",
        thirdLongLat: "",
      },
      rules: {
        name: [{ required: true, message: "请填写片区编号" }],
        area: [
          { required: true, message: "请填写片区面积" },
          {
            validator(rule, value, callback) {
              if (!CU.validateNumber(value)) {
                callback(rule.message);
                return;
              }
              callback();
            },
            message: "请填写正确的面积",
          },
        ],
        mapPosition: [{ required: true, message: "请勾画片区" }],
      },
      farmList: [],
      drawMapModalShow: false,
      search_data: {},
      backgroundPosition: [],
    };
  },
  computed: {
    config() {
      let config = {
        filter: {
          add: {
            addBtnName: "新增片区",
            ca: "someArea_add",
          },
          width: 200,
          filterBox: [
            {
              conditionName: "片区名称",
              component: "input",
              field: "name",
              defaultValue: "",
            },
          ],
        },
        page: { ...this.page },
      };
      return config;
    },
  },
  watch: {
    value(val) {
      if (val) {
        this.getList();
      } else {
        this.table.data = [];
        this.table.loading = false;
      }
    },
  },
  methods: {
    add() {
      this.modal = {
        show: true,
        title: "新增片区",
        submitLoading: false,
      };
    },
    search(data) {
      this.page.pageNo = 1;
      this.search_data = data;
      this.getList();
    },
    pageChange(pageNo) {
      this.page.pageNo = pageNo;
      this.getList();
    },
    getFarm() {
      this.$post(this.$api.BASE_MANAGE.LIST, {
        siteType: "4",
      }).then((res) => {
        this.farmList = res.list;
      });
    },
    drawMap() {
      if (!this.farm) return;
      let backgroundPosition =
        this.farm && this.farm.mapPosition && this.farm.mapPosition
          ? JSON.parse(this.farm.mapPosition)
          : [];

      let data = this.table.data.filter((item) => item.id != this.form.id);
      for (let i = 0; i < data.length; i++) {
        let temp = data[i];
        temp.mapPosition &&
          backgroundPosition.push(...JSON.parse(temp.mapPosition));
      }
      this.backgroundPosition = backgroundPosition;
      this.drawMapModalShow = true;
    },
    drawFinish(data) {
      this.form.mapPosition = JSON.stringify(data);
      this.form.thirdLongLat = data[0].center;
      this.drawMapModalShow = false;
    },

    getList() {
      if (!this.CA("someArea_check")) return;
      this.table.loading = true;
      this.$post(this.$api.PRODUCTION_AREA.LIST, {
        pageNo: this.page.pageNo,
        pageSize: this.page.pageSize,
        siteType: "4",
        farmId: this.farm.id,
        ...this.search_data,
      })
        .then((res) => {
          this.table.data = res.list;
          this.page.total = +res.total;
        })
        .finally(() => {
          this.table.loading = false;
        });
    },

    edit(row) {
      for (let key in this.form) {
        this.form[key] = row[key];
      }
      this.form.farm = row.farmId + "/" + row.siteType;
      this.modal = {
        show: true,
        title: "编辑片区",
        submitLoading: false,
      };
    },

    del(id) {
      this.$post(this.$api.PRODUCTION_AREA.DELETE, {
        id,
      }).then(() => {
        this.$Message.success("删除成功");
        this.getList();
      });
    },

    modalShow(visible) {
      if (visible) return;
      this.form = {
        id: "",
        name: "",
        area: "",
        remark: "",
        mapPosition: "",
        thirdLongLat: "",
        farm: "",
      };
      this.$refs.form.resetFields();
    },
    submit() {
      // let n = JSON.parse(this.form.mapPosition);
      // let features = n.features.filter(
      //   (item) => item.properties.editArea != false
      // );
      // if (features.length == 0) {
      //   this.form.mapPosition = "";
      // } else {
      //   n.features = features;
      //   this.form.mapPosition = JSON.stringify(n);
      // }
      this.$refs.form.validate().then((res) => {
        if (!res) return;
        let params = { ...this.form };
        params.farmId = this.farm.id;
        params.siteType = this.farm.siteType;
        this.modal.submitLoading = true;
        this.$post(
          params.id
            ? this.$api.PRODUCTION_AREA.UPDATE
            : this.$api.PRODUCTION_AREA.ADD,
          params
        )
          .then(() => {
            this.getList();
            this.modal.show = false;
          })
          .finally(() => {
            this.modal.submitLoading = false;
          });
      });
    },
  },
};
</script>

<style lang="less" scoped>
.some-area {
  width: 100%;
  height: 100%;
}
</style>